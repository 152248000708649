//== Colors
//

// Color system

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

// Zonneweelde theme (BS3, less version)
// @gray-darker: #231f20;
// @gray-dark: #2a2a2a;
// @gray: #5f5f5f;
// @gray-light: #ababab;
// @gray-lighter: #f2f2f2;

// @brand-primary: #b5985a;
// @brand-primary-2: #c17822;
// @brand-success: @brand-primary-2;
// @brand-info: @gray-dark;
// @brand-warning: #800829;
// @brand-danger: @brand-warning;

$primary:     #b5985a;
$warning:     #800829;
$light:       #ababab;
$dark:        #2a2a2a;
$secondary:     $gray-600;
$success:     #c17822;
$info:          $gray-800;
$danger:        $warning;

$font-family-sans-serif:  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-serif:       Cardo, Georgia, "Times New Roman", Times, serif;
$font-family-brand:       $font-family-serif;
$font-family-system:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

//## Prices and Promo Text Appearance

//** Color for promo (default is bright green: #008a00)
$brand-promo: #c17822 !default;
$brand-primary: $primary;

//** Price Text appearance (for readability defaults to Arial 16px bold 700)
$price-font-family: $font-family-system;
$price-font-size: 1.25rem;
$price-font-weight: 700;

//** Color for order total (default is bright red #b12704)
$price-total-color: #b12704;

// Headings

$headings-font-family:    $font-family-serif;
$headings-font-weight:    700;
$headings-line-height:    1.1;
$headings-color:          $brand-primary;



//
// Boostrap references
$bootstrapContainerPadding: 15px;

$favoriteBtnWithText: false;

// Boostrap z-index theme settings:
$zindex-navbar: 1000;
$zindex-dropdown: 1000;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-navbar-fixed: 1030;
$zindex-modal-background: 1040;
$zindex-modal: 1050;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

// $enable-caret: true !default;
// $enable-rounded: true !default;
// $enable-shadows: false !default;
// $enable-gradients: false !default;
// $enable-transitions: true !default;
// $enable-prefers-reduced-motion-media-query: true !default;
// $enable-hover-media-query: false !default; // Deprecated, no longer affects any compiled CSS
// $enable-grid-classes: true !default;
// $enable-pointer-cursor-for-buttons: true !default;
// $enable-print-styles: true !default;
// $enable-responsive-font-sizes: false !default;
// $enable-validation-icons: true !default;
// $enable-deprecation-messages: true !default;
$enable-caret: true !default;


// Components
//
// Define common padding and border radius sizes and more.

// $line-height-lg: 1.5 !default;
// $line-height-sm: 1.5 !default;

// $border-width: 1px !default;
// $border-color: $gray-300 !default;

// $border-radius: .25rem !default;
// $border-radius-lg: .3rem !default;
// $border-radius-sm: .2rem !default;

// $rounded-pill: 50rem !default;

// $box-shadow-sm: 0 .125rem .25rem rgba($black, .075) !default;
// $box-shadow: 0 .5rem 1rem rgba($black, .15) !default;
// $box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;

// $component-active-color: $white !default;
// $component-active-bg: theme-color("primary") !default;

// $caret-width: .3em !default;
// $caret-vertical-align: $caret-width * .85 !default;
// $caret-spacing: $caret-width * .85 !default;

// $transition-base: all .2s ease-in-out !default;
// $transition-fade: opacity .15s linear !default;
// $transition-collapse: height .35s ease !default;

$border-radius: .2rem !default;
$border-radius-lg: .25rem !default;
$border-radius-sm: .15rem !default;



// Typography
//
// Font, line-height, and color for body text, headings, and more.

// $font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// $font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// $font-family-base: $font-family-sans-serif !default;

// $font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
// $font-size-lg: $font-size-base * 1.25 !default;
// $font-size-sm: $font-size-base * .875 !default;

// $font-weight-lighter: lighter !default;
// $font-weight-light: 300 !default;
// $font-weight-normal: 400 !default;
// $font-weight-bold: 700 !default;
// $font-weight-bolder: bolder !default;

// $font-weight-base: $font-weight-normal !default;
// $line-height-base: 1.5 !default;

// $h1-font-size: $font-size-base * 2.5 !default;
// $h2-font-size: $font-size-base * 2 !default;
// $h3-font-size: $font-size-base * 1.75 !default;
// $h4-font-size: $font-size-base * 1.5 !default;
// $h5-font-size: $font-size-base * 1.25 !default;
// $h6-font-size: $font-size-base !default;

// $headings-margin-bottom: $spacer / 2 !default;
// $headings-font-family: null !default;
// $headings-font-weight: 500 !default;
// $headings-line-height: 1.2 !default;
// $headings-color: null !default;

// $display1-size: 6rem !default;
// $display2-size: 5.5rem !default;
// $display3-size: 4.5rem !default;
// $display4-size: 3.5rem !default;

// $display1-weight: 300 !default;
// $display2-weight: 300 !default;
// $display3-weight: 300 !default;
// $display4-weight: 300 !default;
// $display-line-height: $headings-line-height !default;

// $lead-font-size: $font-size-base * 1.25 !default;
// $lead-font-weight: 300 !default;

// $small-font-size: 80% !default;

// $text-muted: $gray-600 !default;

// $blockquote-small-color: $gray-600 !default;
// $blockquote-small-font-size: $small-font-size !default;
// $blockquote-font-size: $font-size-base * 1.25 !default;

// $hr-border-color: rgba($black, .1) !default;
// $hr-border-width: $border-width !default;

// $mark-padding: .2em !default;

// $dt-font-weight: $font-weight-bold !default;

// $kbd-box-shadow: inset 0 -.1rem 0 rgba($black, .25) !default;
// $nested-kbd-font-weight: $font-weight-bold !default;

// $list-inline-padding: .5rem !default;

// $mark-bg: #fcf8e3 !default;

// $hr-margin-y: $spacer !default;

$font-size-base: 0.95rem !default;
$line-height-base: 1.4 !default;
$h1-font-size: $font-size-base * 1.9 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.2 !default;
$h4-font-size: $font-size-base * 1.05 !default;
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base * 0.85 !default;



// Body
//
// Settings for the `<body>` element.

$body-bg: #231f20 !default;
$body-color: #f2f2f2 !default;


// Modals

// Padding applied to the modal body
// $modal-inner-padding: 1rem !default;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
// $modal-footer-margin-between: .5rem !default;

// $modal-dialog-margin: .5rem !default;
// $modal-dialog-margin-y-sm-up: 1.75rem !default;

// $modal-title-line-height: $line-height-base !default;

// $modal-content-color: null !default;
$modal-content-bg: $body-bg !default;
// $modal-content-border-color: rgba($black, .2) !default;
// $modal-content-border-width: $border-width !default;
// $modal-content-border-radius: $border-radius-lg !default;
// $modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
// $modal-content-box-shadow-xs: 0 .25rem .5rem rgba($black, .5) !default;
// $modal-content-box-shadow-sm-up: 0 .5rem 1rem rgba($black, .5) !default;

$modal-backdrop-bg: $body-color !default;
// $modal-backdrop-opacity: .5 !default;
// $modal-header-border-color: $border-color !default;
// $modal-footer-border-color: $modal-header-border-color !default;
// $modal-header-border-width: $modal-content-border-width !default;
// $modal-footer-border-width: $modal-header-border-width !default;
// $modal-header-padding-y: 1rem !default;
// $modal-header-padding-x: 1rem !default;
// $modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

// Cards

// $card-spacer-y: .75rem !default;
// $card-spacer-x: 1.25rem !default;
// $card-border-width: $border-width !default;
// $card-border-radius: $border-radius !default;
// $card-border-color: rgba($black, .125) !default;
// $card-inner-border-radius: subtract($card-border-radius, $card-border-width) !default;
// $card-cap-bg: rgba($black, .03) !default;
// $card-cap-color: null !default;
// $card-height: null !default;
$card-color: null !default;
$card-bg: $dark !default;

// $card-img-overlay-padding: 1.25rem !default;

// $card-group-margin: $grid-gutter-width / 2 !default;
// $card-deck-margin: $card-group-margin !default;

// $card-columns-count: 3 !default;
// $card-columns-gap: 1.25rem !default;
// $card-columns-margin: $card-spacer-y !default;


//
//
// Forms

$input-bg: $dark !default;
$input-disabled-bg: $body-bg !default;

$input-color: $body-color !default;
$input-border-color: $gray-600 !default;
// $input-border-width: $input-btn-border-width !default;
// $input-box-shadow: inset 0 1px 1px rgba($black, .075) !default;



// Dropdowns
//
// Dropdown menu container and contents.

// $dropdown-min-width: 10rem !default;
// $dropdown-padding-y: .5rem !default;
// $dropdown-spacer: .125rem !default;
// $dropdown-font-size: $font-size-base !default;
$dropdown-color: $body-color !default;
$dropdown-bg: $dark !default;
$dropdown-border-color: $gray-500 !default;
// $dropdown-border-radius: $border-radius !default;
// $dropdown-border-width: $border-width !default;
// $dropdown-inner-border-radius: subtract($dropdown-border-radius, $dropdown-border-width) !default;
$dropdown-divider-bg: $dropdown-border-color !default;
// $dropdown-divider-margin-y: $nav-divider-margin-y !default;
// $dropdown-box-shadow: 0 .5rem 1rem rgba($black, .175) !default;

$dropdown-link-color: $dropdown-color !default;
// $dropdown-link-hover-color: darken($gray-900, 5%) !default;
// $dropdown-link-hover-bg: $gray-100 !default;

// $dropdown-link-active-color: $component-active-color !default;
// $dropdown-link-active-bg: $component-active-bg !default;

// $dropdown-link-disabled-color: $gray-600 !default;

// $dropdown-item-padding-y: .25rem !default;
// $dropdown-item-padding-x: 1.5rem !default;

$dropdown-header-color: $dropdown-border-color !default;


// Popovers

// $popover-font-size: $font-size-sm !default;
// $popover-bg: $white !default;
// $popover-max-width: 276px !default;
// $popover-border-width: $border-width !default;
// $popover-border-color: rgba($black, .2) !default;
// $popover-border-radius: $border-radius-lg !default;
// $popover-inner-border-radius: subtract($popover-border-radius, $popover-border-width) !default;
// $popover-box-shadow: 0 .25rem .5rem rgba($black, .2) !default;

// $popover-header-bg: darken($popover-bg, 3%) !default;
// $popover-header-color: $headings-color !default;
// $popover-header-padding-y: .5rem !default;
// $popover-header-padding-x: .75rem !default;

$popover-body-color: $gray-800 !default;
// $popover-body-padding-y: $popover-header-padding-y !default;
// $popover-body-padding-x: $popover-header-padding-x !default;

// $popover-arrow-width: 1rem !default;
// $popover-arrow-height: .5rem !default;
// $popover-arrow-color: $popover-bg !default;

// $popover-arrow-outer-color: fade-in($popover-border-color, .05) !default;




//
//
// Custom Variables

$goldenRatio: 1.618;

$bootstrapContainerPadding: 15px;

$totalsFirst: true !default;
$hilightTotal: true !default;
$hilightLastAddedProduct: true !default;
$hilightBasketProductOnHover: false !default;
$hideInactivePromoPrice: true !default;
$conditionsBetweenBrakets: true !default;
$enableInnerShadow: true !default;
$basketContentInnerShadow: 0px -35px 14px -31px rgba(0, 0, 0, 0.1);
$enableAlternateBackgrounds: true !default;
$displayMemberIcon: false !default;
$hasConceptProducts: true !default;

$voidFlex: 2%;
$voidDefault: .85em;
$voidNarrow: $voidDefault / 2;
$voidTiny: $voidDefault / 5;
$lineHeight: 21px;
$wordSpacing: var(--word-spacing);

$colorPrimary: var(--color-primary);

$separatorColor: var(--color-gray-light);
$separatorBgColor: var(--color-gray-lighter);

$priceFontFamily: $price-font-family;
$priceFontSize: var(--font-size-medium);
$priceBigFontSize: var(--font-size-big);
$priceFontWeight: $price-font-weight;
$priceBold: 700;
$priceLegendFallbackColor: #999;
$priceLegendColor: var(--color-gray);
$promoColor: var(--color-group-promo);
$promoColorComplementary: var(--color-white);
$promoFontSize: var(--font-size-promo);
$fallbackPromoColor: #777;
$fallbackPromoColorComplementary: #fff;
$labelFontSize: var(--font-size-label);
$lastAddedBgColor: var(--color-gray-lighter);
$highlightOnHoverFallbackColor: #eee;
$highlightOnHoverColor: var(--color-gray-lighter);

$borderWidth: 1px;
$borderStyle: solid;
$borderColor: #ccc;
$borderDefault: $borderWidth $borderStyle $borderColor;
$borderTransparent: $borderWidth $borderStyle transparent;

$basketDefaultBgColor: #fff;

$basketBodyScrollBarWidth: 18px;

$basketHeaderPadding: 6px;
$basketHeaderFontSize: 12px;
$basketHeaderHeightCalculated: ($basketHeaderPadding*2)+($basketHeaderFontSize + $borderWidth);

$basketFooterFallbackBgColor: #000;
$basketFooterBgColor: $dark;

$desktopFontSize: var(--font-size-base);
$desktopFontSizeSmall: var(--font-size-small);
$desktopFontSizeMedium: var(--font-size-medium);
$desktopFontSizeBig: var(--font-size-big);
$desktopPriceFontSize: var(--font-size-big);

$headingsFontFamily: Cabin, "Helvetica Neue", Helvetica, Arial, system-ui, sans-serif;
$headingsLineHeight: 125%;
$headingsFontWeight: 700;

@mixin heading-style {
    font-family: $headingsFontFamily;
    line-height: $headingsLineHeight;
    font-weight: $headingsFontWeight;
}

$phoneFontSize: var(--font-size-base);
$phoneFontSizeSmall: var(--font-size-small);
$phoneFontSizeBig: var(--font-size-medium);
$phonePriceFontSize: var(--font-size-medium);
$desktopModalContentHeight: 65vh;
$phoneModalContentHeight: 55vh;
$phoneLandscapeModalContentHeight: 45vh;

// Boostrap z-index theme settings:
$zindex-navbar: 1000;
$zindex-dropdown: 1000;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-navbar-fixed: 1030;
$zindex-modal-background: 1040;
$zindex-modal: 1050;