/* cardo-regular - latin */
@font-face {
  font-family: 'Cardo';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/cardo-v11-latin/cardo-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Cardo'), local('Cardo-Regular'),
       url('../fonts/cardo-v11-latin/cardo-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cardo-v11-latin/cardo-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cardo-v11-latin/cardo-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cardo-v11-latin/cardo-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cardo-v11-latin/cardo-v11-latin-regular.svg#Cardo') format('svg'); /* Legacy iOS */
}
/* cardo-italic - latin */
@font-face {
  font-family: 'Cardo';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/cardo-v11-latin/cardo-v11-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Cardo Italic'), local('Cardo-Italic'),
       url('../fonts/cardo-v11-latin/cardo-v11-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cardo-v11-latin/cardo-v11-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cardo-v11-latin/cardo-v11-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cardo-v11-latin/cardo-v11-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cardo-v11-latin/cardo-v11-latin-italic.svg#Cardo') format('svg'); /* Legacy iOS */
}
/* cardo-700 - latin */
@font-face {
  font-family: 'Cardo';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/cardo-v11-latin/cardo-v11-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Cardo Bold'), local('Cardo-Bold'),
       url('../fonts/cardo-v11-latin/cardo-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cardo-v11-latin/cardo-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cardo-v11-latin/cardo-v11-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cardo-v11-latin/cardo-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cardo-v11-latin/cardo-v11-latin-700.svg#Cardo') format('svg'); /* Legacy iOS */
}