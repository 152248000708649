@import "themes/zw-bs4-theme/variables";
@import "node_modules/bootstrap/scss/bootstrap";

// font awesome fonts path (relative to assets/css)
$fa-font-path: "../fonts/font-awesome";
@import "node_modules/font-awesome/scss/font-awesome.scss";

// location of the theme media (icons, svgs, etc.)
$themeMediaPrefix: 'https://cdn.alliedvintners.com/images/interface/zw-theme/';
// location of the page content media
$pageMediaPrefix: 'https://cdn.alliedvintners.com/images/content/zw/page/';
// build type: 'dev' or 'dist' (set in _build-*.yml)
$buildType: 'dist';
$DEBUG: false;
$catalogueStyle: 'default';
// how many columns to show in the catalogue results list?
$catalogueResultsCols: 2;
// Styles refinements
$likesBorders: true;
$likesShadows: true;
$likesRoundedCorners: false;
// other general variables
$separatorChar: '•';

// Webshop styles
@import "bs3-legacy";
@import "sunrise";
@import "mixins";
@import "promo-prices";
@import "promo-labels";
@import "webshop/popup-basket";
@import "webshop/basket";
@import "webshop/checkout";
@import "webshop/catalogue/default/catalogue-grid";

@import "webshop/common/default/common";
@import "supercustomer";
@import "themes/zw-bs4-theme/theme";
@import "readability";
@import "incompatible-browsers";
@import "bootbox";
