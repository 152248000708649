:root {
    --color-white: #fff;
    --color-red: #990524;
    --color-blue: #000e72;
    --color-green: #008a00;
    --color-green-dark: #00543c;
    --color-orange: #ea8c2c;
    --color-yellow: #ffee00;
    --color-gray-light: #ababab;
    --color-gray-lighter: #f2f2f2;
    --color-gray-dark: #2a2a2a;
    --color-gray-darker: #231f20;
    --color-gray: var(--color-gray-light);

    --color-primary: #b5985a;
    --color-group-promo: var(--color-orange);

    --font-size-base: calc(10px + .4vmax);
    --font-size-big: calc(var(--font-size-base) * 1.75);
    --font-size-medium: calc(var(--font-size-base) * 1.2);
    --font-size-small: calc(var(--font-size-base) * .8);
    --font-size-label: calc(var(--font-size-base) * .9);
    --font-size-promo: var(--font-size-base);
}