@import "css-variables";
@import "holy-grail";
@import "fonts";
@import "header";



#popupBasketModal {
    .close {
        color: $body-color;
    }

    .modal-body {
        .basket-container {
            .basket-header {
                &.sticky-top {
                    background-color: $body-bg;
                }
            }
        }
    }
}

.checkout-basket {
    .basket--footer {
        .footer {
            &-subtotals {
                .subtotal {

                    &-qty,
                    &-gross,
                    &-savings,
                    &-order {
                        .content {
                            background-color: $body-color;
                        }
                    }

                    &-qty,
                    &-gross,
                    &-order {
                        .content {
                            color: $body-bg;
                        }
                    }
                }
            }

            &-shipping-methods {
                .shipping-container {
                    .shipping {

                        &--cost {
                            .content {
                                color: $body-bg;
                                background-color: $body-color;
                            }
                        }
                    }
                }
            }

            &-totals {
                .totals-container {
                    .grand-total {
                        &--value {
                            .content {
                                color: $body-bg;
                                background-color: $body-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

.btn {
    // font-weight: $font-weight-bold;

    &-outline {

        // &-primary,
        // &-secondary,
        // &-dark,
        // &-info,
        // &-success,
        // &-warning,
        // &-danger {
        //     background-color: var(--tricolore-bianco);
        // }

        &-dark {
            color: var(--color-gray-lighter);
            border-color: var(--color-gray-lighter);

            &:disabled {
                color: var(--color-gray-lighter);
            }
        }

        &-info {
            color: var(--color-gray-light);
            border-color: var(--color-gray-light);
        }

        &-link {
            color: var(--color-primary);
        }
    }
}